import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import BasicInput from "../form/inputs/BasicInput"
import SubmitButton from "../form/buttons/SubmitButton"
import PrivacyLabel from "../form/PrivacyLabel"
import StyledReCaptcha from "../form/StyledReCaptcha"
import FormButtonsContainer from "../form/buttons/ButtonsContainer"
import * as Yup from "yup"

const ModalContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  gap: 1rem;

  @media (min-width: 40em) {
    gap: 1rem;
  }
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;

  @media (min-width: 40em) {
    flex-direction: column;
    gap: 0rem;
  }
`

const Text = styled(PrivacyLabel)`
  margin-right: auto;

  font-size: 1.125rem;
  font-weight: 400;
  font-family: var(--ff-primary);
  letter-spacing: 0.0015em;
  line-height: 1.265rem;

  text-align: left;

  @media (max-width: 40em) {
    font-family: var(--ff-secondary);
    font-size: 1rem;
    letter-spacing: 0.05em;
    margin-bottom: 17px;
    line-height: 1.125rem;
    font-weight: 500;
  }
`

const TextParagraph = styled.p`
  margin-right: auto;

  font-size: 0.875rem;
  font-weight: 400;
  font-family: var(--ff-primary);
  letter-spacing: 0.0015em;

  text-align: left;

  @media (max-width: 40em) {
    font-family: var(--ff-secondary);
    font-size: 1rem;
    letter-spacing: 0.05em;
  }
`

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const DownloadSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter correct email")
    .required("Email is required"),
})

const ModalMessage = ({ file }) => {
  const [downloadUrl, setDownloadUrl] = useState(false)

  const submitForm = async (values, setSubmitting, setErrors) => {
    try {
      const domain = values.email.split("@")[1]
      const blockedDomains = [
        "gmail.com",
        "yahoo.com",
        "outlook.com",
        "hotmail.com",
        "aol.com",
        "zoho.com",
        "protonmail.com",
        "icloud.com",
        "gmx.com",
        "yandex.com",
        "mail.com",
        "hotmail.com",
        "live.com",
        "msn.com",
      ]

      if (blockedDomains.includes(domain)) {
        setErrors({
          email: `${domain} addresses are not supported. Please use your company email address`,
        })
        setSubmitting(false)
        return
      }
      if (isBrowser) {
        window.grecaptcha.reset()
      }
      const response = await fetch(process.env.GATSBY_DOWNLOAD_LINK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          file: file,
          response: values.response,
        }),
      })
      console.log(response)
      if (!response.ok) {
        const errorResponse = await response.json()
        if (errorResponse.message === "Invalid email address.") {
          setErrors({
            email: `${domain} addresses are not supported. Please use your company email address`,
          })
          setSubmitting(false)
          return
        } else {
          throw new Error("Network response was not ok")
        }
      }
      setDownloadUrl(true)
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error)
    }

    setSubmitting(false)
  }

  return (
    <ModalContent role="alert">
      {!downloadUrl ? (
        <>
          <Text>
            Please enter your company email. We will send you a link to download
            the material you requested.
          </Text>
          <Formik
            initialValues={{ email: "", response: "" }}
            validationSchema={DownloadSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              submitForm(values, setSubmitting, setErrors)
            }}
          >
            {({ setFieldValue, isSubmitting }) => (
              <StyledForm>
                <BasicInput
                  name="email"
                  autoComplete="email"
                  type="email"
                  placeholder="Enter your email"
                  style={{ margin: "0" }}
                />

                <StyledReCaptcha
                  onChange={response => {
                    setFieldValue("response", response)
                  }}
                  onErrored={e => console.error(e)}
                  style={{
                    marginTop: "0",
                  }}
                />
                <FormButtonsContainer>
                  <SubmitButton label="send" isSubmitting={isSubmitting} />
                </FormButtonsContainer>
              </StyledForm>
            )}
          </Formik>
        </>
      ) : (
        <>
          <div>
            <Text>Download Link Sent!</Text>
          </div>
          <div>
            <TextParagraph>
              Please check your inbox (and possibly your spam folder) to access
              the download.
            </TextParagraph>
          </div>
        </>
      )}
    </ModalContent>
  )
}

export default ModalMessage
