import React, { useState } from "react"
import Modal from "./Modal"

const DownloadLink = ({ file, data, children }) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  console.log(showModal)
  console.log(file)
  console.log(data)
  return (
    <>
      <span onClick={toggleModal}>{children}</span>
      <Modal isOpen={showModal} closeModal={toggleModal} file={file} />
    </>
  )
}

export default DownloadLink
